<template>
<v-app>
  <v-main>
    <v-container class="hero fill-height" color="transparent">
      <v-row>
        <v-col>
          <v-card class="pt-5" elevation="0" color="transparent">
            <v-card-text class="text-center">
              <v-img
                :src="require('@/assets/pinkwhitelogo.svg')"
                contain
                width="163"
                class="ma-auto"
              ></v-img>

              <div class="pt-2">
                <h4 class="text-h6 white--text">{{ $t("signin.title") }}</h4>
              </div>
               <div class="pt-6">
                <h4 class="text-subtitle-1 white--text">{{ $t("signin.subtitle") }}</h4>
              </div>
               <!-- <div class="pt-4" v-if="mode!=='production'"> -->
                  <div class="pt-4">
                <v-btn
                large
                block
                color="white"
                style="color:#4E4B66"
                class="text-subtitle-1 font-weight-bold"
                @click="$auth.signInWithAD()"
                
                ><v-icon color="#C7C9D9">mdi-email</v-icon><v-spacer/>{{ $t("signin.signinwithAD") }}
                </v-btn
              >
               </div> 
               <div class="pt-4">
                <v-btn
                large
                block
                color="white"
                style="color:#4E4B66"
                class="text-subtitle-1 font-weight-bold"
                @click="$auth.signInWithEmail()"
                
                ><v-icon color="#C7C9D9">mdi-email</v-icon><v-spacer/>{{ $t("signin.signinwithemail") }}
                </v-btn
              >
               </div>
            <div class="pt-4">
                <v-btn
                large
                block
                color="white"
                class="text-subtitle-1 font-weight-bold"
                style="color:#00B900"
                @click="$auth.signInWithLine()"
                ><v-icon>$vuetify.icons.line</v-icon><v-spacer/>{{ $t("signin.signinwithline") }}
                </v-btn
              >
               </div>
                <div class="pt-4">
                <v-btn
                large
                block
                color="white"
                style="color:#245DDB"
                class="text-subtitle-1 font-weight-bold"
                @click="$auth.signInWithNewPhone()"
                ><v-icon  style="color:#245DDB"> $vuetify.icons.phone</v-icon><v-spacer/>{{ $t("signin.signinwithmobile") }}
                </v-btn
              >
               </div>
            </v-card-text>
            <v-card-actions>
            
              
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <v-dialog v-model="network">
      <NetworkCard @onClose="network = false" />
    </v-dialog>
       <v-overlay :value="loading">
      <v-progress-circular
        indeterminate
        size="64"
      ></v-progress-circular>
    </v-overlay>
     <v-bottom-sheet
      v-model="sheet"
    >
     <v-sheet
        class="text-center"
        height="140px"
      >
       <div class="primary--text text--darken-4 text-h6">
       กรุณาติดตั้งซอฟแวร์ไว้ที่หน้าจอของท่าน
        </div>
         <div class="primary--text text--darken-4">
        1. เลือกเมนู <v-icon color="primary darken-4">mdi-export-variant</v-icon>
        </div>
        <div class="primary--text text--darken-4">
        2. เลือกเพิ่มไปยังหน้าจอโฮม (Add to Home Screen) <v-icon color="primary darken-4">mdi-plus-box-outline</v-icon>
        </div>
       
      </v-sheet>
    </v-bottom-sheet>
  </v-main>
</v-app>
</template>

<script>
import NetworkCard from "@/components/common/NetworkCard";
import { otp } from "@/api/public";

const isIos = () => {
  const userAgent = window.navigator.userAgent.toLowerCase();
  return /iphone|ipad|ipod/.test( userAgent );
}

export default {
  name: "SignIn",
  components: {
    NetworkCard
  },
  mounted () {
  let displayMode = 'browser';
  const mqStandAlone = '(display-mode: standalone)';
  if (navigator.standalone || window.matchMedia(mqStandAlone).matches) {
    displayMode = 'standalone';
  }
        if(displayMode === 'browser' && isIos() ) {
          this.sheet = true
        }
  },
  data() {
    return {
      network: false,
      mobile: "",
      loading:false,
      event: "",
      sheet: false,
      mode: process.env.VUE_APP_MODE
    };
  },
  methods: {
    isDisable(){
      if(!this.event) return true
      if(this.event.length<12) return true
      return false
    },
    signIn() {
      if (this.isOnline) {
          this.loading = true

        otp(
          { data: { mobile: this.mobile.replace(/^(\+)|\D/g, "$1") } },
          (message) => {
            if (message.data.code === 1) {
             this.loading = false
             this.$router.replace({name: 'otp',params: {mobile: this.mobile }})
            }
            this.loading = false
            // this.$router.replace({ path: "otp/"+this.mobile });
          },
          (error) => {
            console.log(error);
          }
        );

        // make network request that returns 'userData' object
        //this.appData = userData
        //this.$offlineStorage.set('user', userData)
      } else {
        this.network = true;
        //this.appData = this.$offlineStorage.get('user')
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.v-btn {
  border-radius: 6px;
}
.theme--light.v-btn.v-btn--disabled.v-btn--has-bg {
    background-color: rgba(128, 160, 233, 0.3) !important;
    color: rgb(255, 255, 255) !important;
}

.hero {
  background: url('../assets/LandingBackground.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
  height: 100vh;
}
</style>